import { template as template_8cf8be5bba54442a9bc943a59a05adf9 } from "@ember/template-compiler";
const FKLabel = template_8cf8be5bba54442a9bc943a59a05adf9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
